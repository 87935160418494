.repositories__inner {
  width: 100%;
  height: 100%;

  .repositories__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    gap: 20px;
    border-bottom: 1px solid var(--line-co);

    input {
      width: 50%;
      border: 1px solid var(--line-co);
      padding: 4px 10px;
      border-radius: 6px;
      color: white;
      background-color: inherit;
    }
  }

  .repositories__container {
    
    .repositories {
      padding: 20px 10px;
      border-bottom: 1px solid var(--line-co);
      color: var(--black300);
      cursor: pointer;

      .repositories__top {
        @include flex-start;
        gap: 20px;

        h3 {
          color: rgb(23, 120, 240);
        }

        span {
          display: block;
          font-size: 12px;
          border-radius: 10px; 
          border: 1px solid var(--line-co);
          padding: 0 7px;
        }

        button {
          cursor: pointer;
          background-color: inherit;
        }
      }

      .repositories__bottom {
        @include flex-start;
        gap: 20px;
        margin-top: 8px;

        span {
          font-size: 12px;
        }

        .lang {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: -10px;
        }
      }
    }
  }
}