.table__header {
  width: 100%;
  border-collapse: collapse;
  color: white;

  th{
    border: 1px solid #ccc;
    padding: 8px;
    text-align: start;
    min-width: 140px;

    &:nth-child(1) {
      width: 40%;
      border-left: none;
    }

    &:nth-child(2) {
      width: 10%;
      text-align: center;
    }

    &:nth-child(3) {
      width: 15%;
      text-align: center;
    }

    &:nth-child(4) {
      width: 45%;
      border-right: none;
    }
  }

  .board__th {
    border-right: none;
  }
}
