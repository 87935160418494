.card__inner {
  padding: 8px;
  width: 298px;
  background-color: var(--inner-co);
  border-radius: 6px;
  margin: 0 auto;

  .card__top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    gap: 8px;  

    .option {
      width: 5px;
      position: relative;

      span {
        cursor: pointer;
      }

      ul {
        padding: 5px;
        position: absolute;
        top: -15px;
        left: 10px;
        width: 75px;
        height: 75px;
        background-color: var(--inner-co);
        border: 1px solid var(--line-co);
        border-radius: 3px;
        z-index: 9999;

        li {
          cursor: pointer;
          text-align: center;
        }
      }
    }

    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      
      input {
        width: 100%;
        background-color: inherit;
        color: white;
        padding: 0 2px;
        letter-spacing: 0.05rem;

        &:hover {
          border: 1px solid #fff;
        }
      }
    }
  }

  .card__middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    input:nth-child(1) {
      width: 60%;
      background-color: inherit;
      color: white;
      padding: 0 5px;
      letter-spacing: 0.05rem;

      &:hover {
        border: 1px solid #fff;
      }
    }

    input:nth-child(2) {
      background-color: inherit;
      color: white;
      padding: 0 5px;
      letter-spacing: 0.05rem;

      &:hover {
        border: 1px solid #fff;
      }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background-color: white;
      border-radius: 3px;
    }

    @media screen and (max-width: 1320px) {
      input[type='date'] {
        width: 100%;
        padding: 8px;
        font-size: 1rem;
      }
    }
  }

  .card__bottom {
    input {
      width: 100%;
      background-color: inherit;
      color: white;
      padding: 0 5px;
      letter-spacing: 0.05rem;

      &:hover {
        border: 1px solid #fff;
      }
    }
  }

  @media screen and (max-width: 1320px) {
    width: 268px;
  }
}