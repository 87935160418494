#boardcontainer {
  width: 100%;
  height: calc(100vh - 186px);
  display: flex;
  justify-content: space-around;
  color: white;
}

.board__section {
  
  .card__container {
    width: 320px;
    padding: 10px;
    height: 100%;
    background-color: var(--inner-dark-co);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
    -ms-overflow-style:none;
    &::-webkit-scrollbar{
      display:none;
    }

    .card__addwork {
      margin-top: 15px;
      text-align: center;
      cursor: pointer;
    }

    @media screen and (max-width: 1320px) {
      width: 288px;
    }
  }
  @media screen and (max-width: 1320px) {
    font-size: 0.8rem;
  }
}