#header {
  @include position-fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10000;
  height: 73px;
  border-bottom: 1px solid var(--line-co);
  background-color: var(--main-bg);
}

.container__inner {
  display: flex;
  height: calc(100vh - 73px);
  position: fixed;
  top: 73px;

  #aside {
    width: 340px;
    z-index: 9999;
    background-color: var(--main-bg);
  }

  #main {
    width: calc(100vw - 340px);
    background-color: var(--main-bg);
    z-index: 9999;
    overflow-y: auto;
    -ms-overflow-style:none;
    &::-webkit-scrollbar{
      display:none;
    }
  }

  @media screen and (max-width: 1000px) {
    display: block;
    width: 100%;

    #aside {
      width: 100%;
      height: 170px;
    }

    #main {
      width: 100%;
      height: calc(100vh - 243px);
      border-top: 1px solid var(--line-co);
    }
  }
}

.work {
  width: 100%;
  
  #aside {
    display: none;
  }

  #main {
    width: 100%;
    height: calc(100vh - 73px);
  }
}