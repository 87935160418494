.aside__inner {
  padding: 20px;
  color: white;

  .aside__inner__top {
    display: flex;
    flex-direction: column;

    .aside__profile {
      margin-top: 10px;
      width: 280px;
      height: 280px;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;
    }

    .aside__info {
      margin-top: 15px;

      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 5px;
      }
    }
  }

  .aside__inner__bottom {
    margin-top: 10px;
  }
  
  @media screen and (max-width: 1000px) {
    width: 100%;
    height: 170px;

    .aside__inner__top {
      flex-direction: row;
      gap: 20px;
      font-size: 0.9rem;

      .aside__profile {
        width: 60px;
        height: 60px;
        margin: 0;
      }
    }

    .aside__inner__bottom {
      margin-top: 20px;
    }
  }
}