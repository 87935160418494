#listcontainer {
  h2 {
    color: white;
    margin-bottom: 10px;
  }

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: white;
    margin-top: 5px;
  }
}

#listSection {
  margin-bottom: 20px;
}