#landing {
  .landing__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 100px;

    @media screen and (max-width: 1320px) {
      padding: 20px;
      flex-direction: column;
      margin-top: 0;
    }
  } 
}

#todocontainer {
  width: 45%;
  .todocontainer__inner {
    border: 1px solid var(--line-co);
    background-color: var(--inner-co);
    border-radius: 6px;
    padding: 10px;
    color: white;

    .todo__add {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      cursor: pointer;
      margin-bottom: 5px;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      td {
        border: 1px solid #ccc;
        padding: 3px 10px;

        div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          width: 100%;
          
          input {
            width: 100%;
            background-color: inherit;
            color: white;
            padding: 0 5px;
          }
        }

        &:nth-child(1) {
          width: 70%;
          border-left: none;
          border-right: none;
        }

        &:nth-child(2) {
          width: 30%;
          border-left: none;
          border-right: none;

          input[type="date"]::-webkit-calendar-picker-indicator {
            background-color: white;
            border-radius: 3px;
          }
        }
      }
    }

  }

  @media screen and (max-width: 1320px) {
    width: 100%;
    margin-top: 25px;
    order: 1;
  }
}