.table__body {
  width: 100%;
  border-collapse: collapse;

  td{
    border: 1px solid #ccc;
    padding: 8px;
    min-width: 140px;
    position: relative;

    &:nth-child(1) {
      width: 40%;
      border-left: none;
      padding: 8px;
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 45%;
      border-right: none;
    }

    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      .option {
        position: relative;

        ul {
          padding: 5px;
          position: absolute;
          top: -15px;
          left: 10px;
          width: 75px;
          height: 80px;
          background-color: var(--inner-co);
          border: 1px solid var(--line-co);
          border-radius: 3px;
          z-index: 9999;
        }
      }
    }

    input {
      background-color: inherit;
      color: white;
      width: 100%;
    }

    .input2 {
      text-align: center;
    }

    .input3 {
      text-align: center;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background-color: white;
      border-radius: 3px;
    }

    @media screen and (max-width: 1320px) {
      input[type='date'] {
        width: 100%;
        padding: 8px;
        font-size: 1rem;
      }
    }
  }
}

#editrow {
  td {
    border-bottom: none;
  }
}