.work__top {
  width: 95%;
  margin: 0 auto;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: white;

  select {
    background-color: inherit;
    color: white;
    font-size: 12px;
    padding: 0 5px;
    
    option {
      color: black;
    }
  }
}

.work__inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 1320px) {
    gap: 0;
  }
}

.no-gap {
  gap: 0;
  padding: 10px;
  color: white;
  overflow-y: auto;
}